@use "src/styles.scss" as *;

app-main-content {
  min-height: 100%;
}

body {

  background-color: var(--background-color);
  color: var(--text-color);

  font-family: 'Figtree', sans-serif;

  hyphens: auto;

  button {
    font-family: 'Figtree', sans-serif;
  }

  a,
  a:visited,
  a:hover {
    text-decoration: none;
    color: $primary;
  }

}
